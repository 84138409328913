import React from "react";

function Home() {
    return (
        <main>
            <div className="main-left">
                <h1>Joel Hernandez</h1>
            </div>
            <div className="main-right">
                <p>
                    BIO HERE BIO HERE BIO HERE. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque pharetra augue ut congue congue. Aliquam erat volutpat. Donec tempus, ligula a rhoncus laoreet, diam libero lobortis libero, sed dapibus felis tellus a urna. Nullam vel lorem risus. Donec tempor tellus ut elit luctus tincidunt. Curabitur convallis nisl a pellentesque facilisis. Morbi euismod odio a leo pulvinar, aliquet interdum ante convallis. Nullam vehicula condimentum blandit. Aenean tempor eleifend dolor sit amet ullamcorper. Sed vehicula purus ac purus tincidunt, a semper diam imperdiet. Quisque dapibus rutrum ligula at sollicitudin.
                    Morbi massa arcu, congue nec dui ac, laoreet auctor nulla. Etiam vestibulum eros ut lacus porta congue. Donec vulputate scelerisque augue id vestibulum. Donec condimentum venenatis rutrum. Phasellus at diam ornare, sodales nibh a, mollis leo. Donec nulla felis, rutrum sit amet porttitor sit amet, porta et nunc. Cras lobortis eros id libero luctus, a placerat leo tempus. Donec hendrerit turpis sed lobortis cursus.
                </p>
            </div>
        </main>
    )
}

export default Home;