const navbar = [
    {
        pageTitle: "Home",
        endpoint: "home"
    },
    {
        pageTitle: "Capture the Flags",
        endpoint:"captureflags"
    },
    {
        pageTitle: "Home Network",
        endpoint: "homenetwork"
    }
]

const footer = [
    {
        pageTitle: "LinkedIn",
        pageURL: "https://www.linkedin.com/in/joel-hernandez/"
    }
]

export { navbar, footer };