import React from "react";

function Link(props) {
    return (
        <a className="bar-link" href={props.URL} >
            <li className="list-item" id={props.endpoint} onClick={props.handleClick}>{props.title}</li>
        </a>
    )
}

export default Link;