import React from "react";
import { footer } from "../content";
import Link from "./Link";

function Footer() {
    const now = new Date();
    const currentYear = now.getFullYear();

    return (
        <footer>
            <p>© {currentYear} Joel Hernandez. Made by Luiza Benvenuti Hernandez. </p>
            <div className="footer-right">
                {footer.map((item)=>{
                    return <Link
                        key={item.pageTitle}
                        title={item.pageTitle}
                        URL={item.pageURL}
                        endpoint={item.pageTitle}
                    />
                })}
            </div>
        </footer>
    )
}

export default Footer;