import React, { useState } from "react";
import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import CaptureFlags from "./components/CaptureFlags";
import HomeNetwork from "./components/HomeNetwork";

function App() {
  const [page, setPage] = useState("");
  function pageUpdate(e) {
    e.preventDefault();
    const btnName = e.target.id;
    setPage(() => {
      if (btnName === "home") {
        return "";
      } else if (btnName === "captureflags") {
        return <CaptureFlags />;
      } else if (btnName === "homenetwork") {
        return <HomeNetwork />;
      }
    });
  }

  return (
    <div>
      <Header onClick={pageUpdate} />
      {page === "" ? <Home /> : page}
      <Footer />
    </div>
  );
}

export default App;
