import React from "react";
import { navbar } from "../content";
import Link from "./Link";

function Header(props) {
    return (
        <nav>
            <ul className="nav-container">
                {navbar.map((item)=>{
                    return <Link
                        key={item.pageTitle}
                        title={item.pageTitle}
                        URL=""
                        endpoint={item.endpoint}
                        handleClick={props.onClick}
                    />
                })}
            </ul>
        </nav>
    )
}

export default Header;